(function(window) {
  'use strict';

  /**
   * Test if a given key is to be included or excluded
   *
   * @param  {String} key     The key to test
   * @param  {Object} options Options for the keys to include/exclude
   * @return {Boolean}        Whether or not to include the key
   */
  function filter(key, options) {
    if (options.include) {
      return options.include.indexOf(key) > -1;
    }

    if (options.exclude) {
      return !(options.exclude.indexOf(key) > -1);
    }

    return true;
  }

  /**
   * Autobind methods to instance
   *
   * @author Sindre Sorhus
   * @see    https://github.com/sindresorhus/auto-bind
   * @param  {Object} instance The current instance
   * @param  {Object} options Options for methods to include/exclude
   */
  function autobind(instance, options) {
    options = window.assign({}, options);
    var methods = Object.getOwnPropertyNames(instance.constructor.prototype);
    forEach(methods, function(method) {
      var value = instance[method];
      if (
        method !== 'constructor' &&
        typeof value === 'function' &&
        filter(method, options)
      ) {
        instance[method] = value.bind(instance);
      }
    });
  }

  // Expose globally
  window.autobind = autobind;
})(window);
